import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { Router, RouterLink, RouterLinkActive, RouterModule, RouterOutlet } from '@angular/router';

import { WhatsappContactComponent } from './components/shared/whatsapp-contact/whatsapp-contact.component';
import { AuthenticationService } from './services/authentication/authentication.service';

@Component({
  selector: "app-root",
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatListModule,
    RouterModule,
    RouterOutlet,
    WhatsappContactComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.css",
})
export class AppComponent implements OnInit {
  private readonly _authenticationService = inject(AuthenticationService);
  private readonly router = inject(Router);

  get isLoggedIn(): boolean {
    return this._authenticationService.isLoggedIn();
  }

  title = "itc-payments"
  ngOnInit() {
    document.addEventListener('click', this.handleClickOutside.bind(this));
  }

  redirectToITC() {
    window.location.href = 'https://itcnet.com.br/';
  }

  redirectToLoginPage(): void {
    this._authenticationService.redirectToLoginPage();
  }

  redirectToRegisterPage(): void {
    this.router.navigate(
      ['/registrar']
    );
  }

  redirectToAccount(): void {
    this.router.navigate(
      ['/conta']
    );
  }

  redirectToHome(): void {
    this.router.navigate(
      ['/planos']
    );
  }

  redirectToLogout(): void {
    this._authenticationService.logout();
  }

  toggleMenu(): void {
    const navLinks = document.getElementById('nav-links');
    navLinks!.classList.toggle('active');
  }

  closeMenu(): void {
    const navLinks = document.getElementById('nav-links');
    if (navLinks) {
      navLinks.classList.remove('active');
    }
  }

  handleClickOutside(event: MouseEvent): void {
    const menu = document.querySelector('.nav-links');
    const hamburger = document.querySelector('.hamburger');

    if (menu && hamburger && !menu.contains(event.target as Node) && !hamburger.contains(event.target as Node)) {
      menu.classList.remove('active');
    }
  }

}
