import { Routes } from '@angular/router';

import { AuthGuard } from './guard/auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'planos',
    pathMatch: 'full'
  },
  {
    path: 'planos',
    loadComponent: () =>
      import('./components/storefront/storefront.component').then(m => m.StorefrontComponent)
  },
  {
    path: 'registrar',
    loadComponent: () =>
      import('./components/registration/registration.component').then(m => m.RegistrationComponent)
  },
  {
    path: 'pagamento',
    loadComponent: () =>
      import('./components/payment/payment.component').then(m => m.PaymentComponent),
    canActivate: [AuthGuard]
  },
  {
    path: 'teste_gratis',
    loadComponent: () =>
      import('./components/trial/trial.component').then(m => m.TrialComponent),
    canActivate: [AuthGuard]
  },
  {
    path: 'conta',
    loadComponent: () =>
      import('./components/account/account.component').then(m => m.AccountComponent),
    canActivate: [AuthGuard]
  }
];
