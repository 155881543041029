<header>
  <nav>
    <a (click)="redirectToITC();" class="logo-link">
      <img src="../assets/logos/itc-blue-wt.png" class="itc-navbar-logo" alt="ITC Logo">
    </a>
    <div id="nav-links" class="nav-links">
      <ul>
        <li class="secondary-function">
          <button mat-flat-button (click)="redirectToHome(); closeMenu()">
            <mat-icon class="icon-spacing">home</mat-icon>Início</button>
        </li>

        <li class="secondary-function">
          <button mat-flat-button (click)="redirectToITC()">
            <mat-icon class="icon-spacing">business</mat-icon>Portal ITC</button>
        </li>

        <li *ngIf="!isLoggedIn" class="secondary-function">
          <button mat-flat-button (click)="redirectToLoginPage(); closeMenu()">
            <mat-icon class="icon-spacing">login</mat-icon>Login</button>
        </li>

        <li *ngIf="!isLoggedIn">
          <button mat-flat-button class="primary-button" (click)="redirectToRegisterPage(); closeMenu()">
            <mat-icon class="icon-spacing">person_add</mat-icon>Inscrever-se</button>
        </li>

        <li *ngIf="isLoggedIn" class="secondary-function">
          <button mat-flat-button (click)="redirectToAccount(); closeMenu()">
            <mat-icon class="icon-spacing">person</mat-icon>Conta</button>
        </li>

        <li *ngIf="isLoggedIn">
          <button mat-flat-button class="primary-button" (click)="redirectToLogout(); closeMenu()">
            <mat-icon class="icon-spacing">logout</mat-icon>Sair</button>
        </li>
      </ul>
    </div>
    <button class="hamburger" (click)="toggleMenu()">&#9776;</button>
  </nav>
</header>

<router-outlet></router-outlet>

<app-whatsapp-contact></app-whatsapp-contact>

<footer class="main-footer">
  <div class="footer-structure">
    <div class="footer-container">
      <div class="footer-column">
        <h3>Empresa</h3>
        <p><a href="https://itcnet.com.br/quem_somos.php">Sobre nós</a></p>
        <p><a href="https://itcnet.com.br/missao.php">Nossos valores</a></p>
        <p><a href="https://itcnet.com.br/trabalhe_conosco.php">Carreiras</a></p>
      </div>
      <div class="footer-column">
        <h3>Curso</h3>
        <p><a href="https://itcnet.com.br/curso_online/index.php">Online</a></p>
        <p><a href="https://itcnet.com.br/curso/index1.php">Presenciais</a></p>
        <p><a href="https://www.itccursosetreinamentos.com.br/">Loja Virtual</a></p>
      </div>
      <div class="footer-column">
        <h3>Contato</h3>
        <p>itc&#64;itcnet.com.br</p>
        <p>Consultoria: (48) 3205-2200</p>
        <p>Vendas: <span>(48) 3205-2210</span> / <span>0800-7248900</span></p>
      </div>
    </div>
  </div>
  <mat-divider class="footer-divider"></mat-divider>

  <div class="footer-bottom">
    <span>&#169; 1994-2025 - ITC Informativo Tributário Contábil</span>
    <button mat-flat-button class="primary-button" onclick="window.sendAdoptCommand('open')">Privacidade</button>
    <p><a href="https://itcnet.com.br/politica/politica_cookies.pdf">Política de Cookie</a></p>
    <p><a href="https://itcnet.com.br/politica/politica_privacidade.pdf">Política de Privacidade</a></p>
  </div>

  <div class="footer-bottom">
    <span>ITC 1.1.2</span>
  </div>
</footer>