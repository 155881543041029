import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { AuthenticationService } from '../services/authentication/authentication.service';

export const AuthGuard: CanActivateFn = (): Observable<boolean> => {
  const router = inject(Router);
  const authenticationService = inject(AuthenticationService);

  return of(authenticationService.isLoggedIn()).pipe(
    switchMap(isLoggedIn => isLoggedIn ?
      from(authenticationService.getToken()) : of(false)
    ),
    map(token => {
      if (!token) {
        authenticationService.showAuthenticationMessage();
        router.navigate(['/registrar']);
        return false;
      }

      if (!authenticationService.isTokenExpired()) {
        return true;
      } else {
        authenticationService.showAuthenticationMessage();
        window.location.reload();
        return false;
      }
    }),
    catchError(error => {
      router.navigate(['/registrar']);
      return of(false);
    })
  );
};
