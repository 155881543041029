import { Component } from '@angular/core';

@Component({
  selector: 'app-whatsapp-contact',
  standalone: true,
  imports: [],
  templateUrl: './whatsapp-contact.component.html',
  styleUrl: './whatsapp-contact.component.css'
})
export class WhatsappContactComponent {

  closeFloater() {
    const container = document.getElementById('whatsapp-container');
    if (container) {
      container.style.display = 'none';
    }
  }

}
